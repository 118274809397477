<template>
  <div v-loading="loading" class="add-action-button" v-if="!refresh">
    <el-form label-position="right">
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Add Button Label">
              <p style="color: red; font-weight: bold">*</p>
              <el-input
                type="text"
                v-model="field.label"
                placeholder="Enter label"
              ></el-input>
            </el-form-item>
          </div>
        </el-col>

        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Type">
              <el-select
                v-model="field.action_button_type"
                placeholder="Select Button Type"
                @change="getRepeatableTemplates"
                style="width: 100%"
              >
                <el-option
                  v-for="(type, index) of buttonTypes"
                  :key="index"
                  :label="type.label"
                  :value="type.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <div class="float-right">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-plus"
            @click="addNewAction"
            >Add</el-button
          >
        </div>
      </el-row>
      <el-row class="mt-1">
        <el-scrollbar wrap-style="max-height: 200px;">
          <el-row
            :gutter="20"
            v-for="(action, i) in field.actions"
            :key="i"
            style="margin-bottom: 3px; padding-bottom: 10px"
          >
            <el-col :span="5">
              <el-select
                placeholder="Select type"
                v-model="action.action_button_action_type"
                style="width: 100%"
                @change="handleActionTypeChange(action)"
                clearable
              >
                <el-option
                  v-for="(type, index) of actionTypes"
                  :key="index"
                  :label="type.label"
                  :value="type.value"
                  :disabled="checkDisabled(type.value, i)"
                  >{{ type.label }}</el-option
                >
              </el-select>
            </el-col>
            <template v-if="action.action_button_action_type == 'NAVIGATION'">
              <el-col
                :span="
                  action.action_button_action_navigation_type == 'OPEN_ENTITY'
                    ? 5
                    : 6
                "
              >
                <el-select
                  placeholder="Select type"
                  v-model="action.action_button_action_navigation_type"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(type, index) of navigationTypes"
                    :key="index"
                    :label="type.label"
                    :value="type.value"
                    >{{ type.label }}</el-option
                  >
                </el-select>
              </el-col>
              <el-col
                :span="6"
                v-if="
                  action.action_button_action_navigation_type ==
                  'OPEN_FORM_BUILDER'
                "
              >
                <el-select
                  v-model="action.action_button_formbuilder_id"
                  placeholder="Select form builder"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(type, index) of formbuilderList"
                    :key="type._id + index"
                    :label="type.name"
                    :value="type._id"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col
                :span="6"
                v-if="
                  action.action_button_action_navigation_type ==
                  'OPEN_DOCUMENT_TEMPLATE_BUILDER'
                "
              >
                <el-select
                  v-model="action.action_button_document_template_id"
                  placeholder="Select document template"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(type, index) of documentTemplatesList"
                    :key="type.value + index"
                    :label="type.title"
                    :value="type.value"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col
                :span="6"
                v-if="
                  action.action_button_action_navigation_type ==
                  'OPEN_ENTITY_VIEW'
                "
              >
                <el-select
                  v-model="action.action_button_entity_view_id"
                  placeholder="Select Entity View"
                  style="width: 100%"
                  filterable
                >
                  <el-option
                    v-for="(entityView, index) of entityViews"
                    :key="index"
                    :label="entityView.name"
                    :value="entityView._id"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col
                :span="4"
                v-if="
                  action.action_button_action_navigation_type == 'OPEN_ENTITY'
                "
              >
                <el-select
                  v-model="action.action_button_entity_id"
                  placeholder="Select Entity"
                  style="width: 100%"
                  filterable
                  @change="getFilters(action)"
                >
                  <el-option
                    v-for="(entity, index) of entities"
                    :key="index"
                    :label="entity.name"
                    :value="entity._id"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col
                :span="4"
                v-if="
                  action.action_button_action_navigation_type == 'OPEN_ENTITY'
                "
              >
                <el-select
                  v-model="action.action_button_entity_filter_id"
                  placeholder="Select Filter"
                  style="width: 100%"
                  filterable
                >
                  <el-option
                    v-for="(filter, index) of entityFilters[
                      action.action_button_entity_id
                    ]"
                    :key="index"
                    :label="filter.filter_name"
                    :value="filter._id"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col
                :span="
                  action.action_button_action_navigation_type == 'OPEN_ENTITY'
                    ? 5
                    : 6
                "
              >
                <el-select
                  placeholder="Select type"
                  v-model="action.action_button_target_location"
                  style="width: 100%"
                >
                  <el-option
                    v-for="(type, index) of locationTypes"
                    :key="index"
                    :label="type.label"
                    :value="type.value"
                    >{{ type.label }}</el-option
                  >
                </el-select>
              </el-col>
              <el-col
                :span="6"
                v-if="action.action_button_action_navigation_type == 'OPEN_URL'"
              >
                <el-input
                  v-model="action.action_button_target_link"
                  placeholder="Enter url"
                ></el-input>
                <span
                  style="color: red"
                  v-if="
                    action.action_button_target_link &&
                    !isValidURL(action.action_button_target_link)
                  "
                  >Invalid URL</span
                >
              </el-col>
            </template>
            <template
              v-else-if="action.action_button_action_type == 'TEMPLATE_ACTION'"
            >
              <el-col :span="6">
                <div class="mt-1">
                  Note: You can apply form rules on this action button
                </div>
              </el-col>
            </template>
            <template
              v-else-if="action.action_button_action_type == 'CREATE_DUPLICATE'"
            >
              <el-col :span="6">
                <el-select
                  v-model="action.selected_fields"
                  placeholder="select Fields"
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="item in fieldsData"
                    :disabled="checkIsFieldRequired(item)"
                    :key="item.key"
                    :label="item.label"
                    :value="item.template_id + '#' + item.key"
                  ></el-option>
                </el-select>
              </el-col>
            </template>
            <template
              v-else-if="action.action_button_action_type == 'ADD_OTHER_ENTITY'"
            >
              <el-col :span="8">
                <el-select
                  placeholder="Select an entity"
                  v-model="action.selected_other_entity"
                  style="width: 100%"
                  filterable
                >
                  <el-option
                    v-for="entity in entities"
                    :key="entity._id"
                    :value="entity._id"
                    :label="entity.name"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="4" class="ml-1">
                <el-tooltip
                  placement="top"
                  content="You can simply add entity data of the selected entity and can map any template fields data to the selected entity"
                  v-if="action.selected_other_entity"
                >
                  <i class="el-icon-info info-color"></i>
                </el-tooltip>
                <el-button
                  v-if="action.selected_other_entity"
                  type="text"
                  @click="openMapFieldsParentToChild(action)"
                  >Map fields</el-button
                >
              </el-col>
            </template>
            <template
              v-else-if="
                action.action_button_action_type == 'EDIT_OTHER_ENTITY'
              "
            >
              <el-col :span="8">
                <el-select
                  placeholder="Select an entity"
                  v-model="action.selected_other_entity_for_edit"
                  style="width: 100%"
                  filterable
                >
                  <el-option
                    v-for="field in fieldsData.filter(
                      (e) => e.inputType == 'ENTITY'
                    )"
                    :key="field.key"
                    :label="field.label"
                    :value="field.entity_id + '#' + field.key"
                  ></el-option>
                </el-select>
                <span class="warning"
                  >*You can simply edit entity data of the selected
                  entity*</span
                >
              </el-col>
            </template>
            <template
              v-else-if="action.action_button_action_type == 'DOWNLOAD_DATA'"
            >
              <el-col :span="6">
                <el-select
                  placeholder="Select an entity"
                  v-model="action.selected_entity_for_download"
                  style="width: 100%"
                  filterable
                >
                  <el-option
                    label="SELF ENTITY"
                    value="self_entity"
                  ></el-option>
                  <el-option
                    label="CURRENT TEMPLATE"
                    value="current_template"
                  ></el-option>
                  <el-option
                    v-for="field in fieldsData.filter(
                      (e) => e.inputType == 'ENTITY'
                    )"
                    :key="field.key"
                    :label="field.label"
                    :value="field.entity_id + '#' + field.key"
                  ></el-option>
                </el-select>
              </el-col>
            </template>
            <template
              v-else-if="
                action.action_button_action_type == 'ESTABLISH_RELATIONSHIP'
              "
            >
              <el-col :span="5">
                <el-select
                  v-model="action.relationship_other_entity"
                  placeholder="Select parent"
                  filterable
                  @change="getRelatedFieldsForSelectedEntity"
                >
                  <el-option
                    label="SELF ENTITY"
                    value="self_entity"
                  ></el-option>
                  <el-option
                    v-for="item in fieldsData.filter(
                      (e) => e.inputType == 'ENTITY'
                    )"
                    :key="item.key"
                    :label="item.label"
                    :value="item.entity_id + '#' + item.key"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="1">
                <div style="font-size: 30px">
                  <i class="el-icon-right"></i>
                </div>
              </el-col>
              <el-col :span="8">
                <el-select
                  placeholder="Entity one"
                  v-model="action.relational_entity_one"
                  style="width: 100%"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="entity in matchingRelationEntities"
                    :key="entity._id"
                    :value="entity._id"
                    :label="entity.name"
                  ></el-option>
                </el-select>
                <el-radio-group
                  v-model="action.action_button_relationship_type"
                  v-if="action.relational_entity_one"
                >
                  <el-tooltip
                    placement="top"
                    :content="
                      getTooltipContent(
                        'assign_only',
                        action.relational_entity_one
                      )
                    "
                  >
                    <el-radio :label="1">Assign only</el-radio>
                  </el-tooltip>
                  <el-tooltip
                    placement="bottom"
                    :content="
                      getTooltipContent(
                        'add_only',
                        action.relational_entity_one
                      )
                    "
                  >
                    <el-radio :label="2">Add only</el-radio>
                  </el-tooltip>
                  <el-tooltip
                    placement="top"
                    :content="
                      getTooltipContent(
                        'assign_add',
                        action.relational_entity_one
                      )
                    "
                  >
                    <el-radio :label="3">Assign & Add</el-radio>
                  </el-tooltip>
                  <el-tooltip
                    placement="bottom"
                    :content="
                      getTooltipContent(
                        'view_data',
                        action.relational_entity_one
                      )
                    "
                  >
                    <el-radio :label="4">View data</el-radio>
                  </el-tooltip>
                  <el-tooltip
                    placement="bottom"
                    :content="
                      getTooltipContent(
                        'view_add',
                        action.relational_entity_one
                      )
                    "
                  >
                    <el-radio :label="5">View & Add</el-radio>
                  </el-tooltip>
                </el-radio-group>
                <el-tooltip
                  placement="right"
                  content="Map fields data from current template fields to relational entity fields"
                >
                  <el-button
                    v-if="
                      action.action_button_relationship_type == 3 ||
                      action.action_button_relationship_type == 2 ||
                      action.action_button_relationship_type == 5
                    "
                    type="text"
                    @click="openMapFieldsParentToChild(action)"
                    class="ml-1"
                    >Map fields</el-button
                  >
                </el-tooltip>
              </el-col>
            </template>
            <el-col :span="1">
              <el-tooltip
                effect="dark"
                content="Delete action"
                placement="right"
              >
                <el-link
                  class="mt-1"
                  type="danger"
                  :underline="false"
                  @click="deleteAction(i)"
                  icon="el-icon-delete"
                ></el-link>
              </el-tooltip>
            </el-col>
            <el-col
              class="ml-2 mt-1"
              v-if="action.relational_entity_one"
              :span="24"
            >
              <span class="after-action">
                <i class="el-icon-info"></i> After adding data through action
                button, which action would you want to be done ->
              </span>
              <el-select
                placeholder="Select action"
                v-model="action.call_back"
                style="width: 18%"
                filterable
                clearable
                size="mini"
              >
                <el-option
                  v-for="entity in callBacks"
                  :key="entity.value"
                  :value="entity.value"
                  :label="entity.label"
                ></el-option>
              </el-select>
            </el-col>
            <el-col
              v-if="action.action_button_action_type == 'DOWNLOAD_DATA'"
              :span="24"
              class="mt-1"
            >
              <span class="warning">*NOTE:</span>
              <ul class="warning">
                <li>
                  -> You can download the self entity data or other entity data
                  if a document template is configured for that entity.
                </li>
                <li>
                  -> You can download multiple entity data as a zip file if the
                  entity field allows to select multiple data.
                </li>
              </ul>
            </el-col>
          </el-row>
        </el-scrollbar>
      </el-row>
      <el-row style="border-top: 1px dotted #e6e6e6">
        <b>Style settings</b>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <div class="form-group">
            <el-form-item label="Font family">
              <el-select
                v-model="field.styles.font.name"
                placeholder="Select font"
                style="width: 100%"
              >
                <el-option
                  v-for="(type, index) of fontTypes"
                  :key="index"
                  :label="type.label"
                  :value="type.value"
                  :style="`font-family: ${type.value}`"
                  >{{ type.label }}</el-option
                >
              </el-select>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="10" v-if="field.action_button_type == 'BUTTON'">
          <div class="form-group">
            <el-form-item label="Button Type">
              <el-radio-group v-model="field.styles.button_type">
                <el-radio-button label="Rectangular"></el-radio-button>
                <el-radio-button label="Rounded"></el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Button Size">
              <el-radio-group v-model="field.styles.button_size">
                <el-radio-button label="Mini"></el-radio-button>
                <el-radio-button label="Small"></el-radio-button>
                <el-radio-button label="Large"></el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="form-group d-flex" style="margin-left: 15px">
            <el-form-item label="Text">
              <el-color-picker
                v-model="field.styles.label_color"
              ></el-color-picker>
            </el-form-item>
            <el-form-item label="Background">
              <el-color-picker
                v-model="field.styles.input_background"
              ></el-color-picker>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <dialog-component
      title="Map fields"
      :visible="fieldsMappingModal"
      @before-close="resetFieldMappingModal"
      :containerMaxHeight="'90%'"
      :containerWidth="'65%'"
    >
      <div class="vue-data-table-default" v-loading="loading">
        <div
          :style="{
            'max-height': '65vh',
            'overflow-y': 'auto',
            'scrollbar-width': 'thin',
          }"
        >
          <el-table
            :data="relatedEntityFields"
            style="width: 100%"
            :loading="loading"
            class="map-table"
          >
            <el-table-column
              :label="relatedEntityName + ' Fields'"
              prop="label"
            >
              <template slot-scope="scope">{{
                scope.row.template_name + " - " + scope.row.label
              }}</template>
            </el-table-column>
            <el-table-column width="100">
              <div style="font-size: 16px">
                <i class="el-icon-right"></i>
              </div>
            </el-table-column>
            <el-table-column label="Template" prop="label">
              <template slot-scope="scope">
                <el-select
                  v-model="selectedMappedTemplate[scope.row.key]"
                  placeholder="Select mapping field"
                  size="mini"
                  clearable
                  class="slect-style"
                >
                  <el-option
                    value="current_template"
                    label="Current template"
                  ></el-option>
                  <el-option
                    v-for="field of repeatingTemplates.filter(
                      (e) => e._id !== selfTemplateId
                    )"
                    :key="field._id"
                    :label="field.name"
                    :value="field._id"
                    >{{ field.name }}</el-option
                  >
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Fields" prop="label">
              <template slot-scope="scope">
                <el-select
                  v-if="selectedMappedTemplate[scope.row.key]"
                  v-model="selectedMappedField[scope.row.key]"
                  placeholder="Select mapping field"
                  size="mini"
                  clearable
                  class="slect-style"
                >
                  <el-option
                    v-for="field of getAnyTemplateFields(
                      scope.row,
                      selectedMappedTemplate[scope.row.key]
                    )"
                    :key="field.key"
                    :label="field.label"
                    :value="field.template_id + '#' + field.key"
                    >{{ field.label }}</el-option
                  >
                </el-select>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetFieldMappingModal">Cancel</el-button>
        <el-button type="primary" @click="saveMapping">Save changes</el-button>
      </span>
    </dialog-component>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import { postAPICall } from "@/helpers/httpHelper";
import { fetchAllEntityFiltersByEntity } from "../../../repo/filtersRepo";

export default {
  name: "templates-formComponents-ActionButton",
  components: {},
  mixins: [TemplateBuilderHelper],
  props: ["field", "fieldsData", "selfTemplateId", "allFormTemplates"],
  computed: {
    ...mapGetters("companyTemplates", [
      "getAllCompanyTemplatesData",
      "getSingleCompanyTemplate",
    ]),
    ...mapGetters("auth", ["getActiveWorkspace"]),
    ...mapGetters("company", [
      "getContactLoginSlug",
      "getUpdateCompany",
      "getCompanyDetails",
    ]),
    ...mapGetters("documents", ["getAllTemplates"]),
    ...mapGetters("formBuilders", ["getAllFormBuildersData"]),
    ...mapGetters("entities", ["getAllEntities"]),
    ...mapGetters("entityRelationships", ["getEntityRelationships"]),
    repeatingTemplates() {
      return this.allFormTemplates
        ? this.allFormTemplates
        : this.getAllCompanyTemplatesData
        ? this.getAllCompanyTemplatesData.data || []
        : [];
    },
  },
  data() {
    return {
      fontTypes: [
        {
          value: "Calibri",
          label: "Calibri",
        },
        {
          value: "Helvetica",
          label: "Helvetica",
        },
        {
          value: "Georgia",
          label: "Georgia",
        },
      ],
      locationTypes: [
        {
          value: "SELF",
          label: "Same Page",
        },
        {
          value: "NEW_TAB",
          label: "New Tab",
        },
        {
          value: "NEW_WINDOW",
          label: "New Window",
        },
      ],
      buttonTypes: [
        {
          label: "Button",
          value: "BUTTON",
        },
        {
          label: "HyperLink",
          value: "HYPERLINK",
        },
      ],
      actionTypes: [
        {
          value: "NAVIGATION",
          label: "Navigation",
        },
        {
          value: "TEMPLATE_ACTION",
          label: "Update template",
        },
        {
          value: "ESTABLISH_RELATIONSHIP",
          label: "Relationships",
        },
        {
          value: "CREATE_DUPLICATE",
          label: "Duplicate Data",
        },
        {
          value: "ADD_OTHER_ENTITY",
          label: "Add Entity Data",
        },
        {
          value: "EDIT_OTHER_ENTITY",
          label: "Edit Entity Data",
        },
        {
          value: "DOWNLOAD_DATA",
          label: "Download Data",
        },
      ],
      navigationTypes: [
        {
          value: "OPEN_URL",
          label: "Open URL",
        },
        {
          value: "OPEN_FORM_BUILDER",
          label: "Open Form builder",
        },
        {
          value: "OPEN_DOCUMENT_TEMPLATE_BUILDER",
          label: "Open document template",
        },
        {
          value: "OPEN_ENTITY",
          label: "Open Entity",
        },
        {
          value: "OPEN_ENTITY_VIEW",
          label: "Open Entity View",
        },
      ],
      entities: [],
      entityViews: [],
      selectedTemplateFields: [],
      childEntities: [],
      relationShipConfiguration: [],
      loading: false,
      reloadRelationship: false,
      refresh: true,
      formbuilderList: [],
      documentTemplatesList: [],
      callBacks: [
        {
          value: "CLOSE_PARENT",
          label: "Close parent",
        },
        {
          value: "REFRESH_PARENT",
          label: "Refresh parent",
        },
        {
          value: "UPDATE_PARENT",
          label: "Update template data",
        },
      ],
      fieldsMappingModal: false,
      allTemplates: [],
      relatedEntityFields: [],
      selectedMappedField: {},
      selectedMappedTemplate: {},
      matchingRelationEntities: [],
      relationshipMappingObj: {},
      relatedEntityName: "",
      entityFilters: {},
    };
  },
  async mounted() {
    this.loading = true;
    if (!this.field.styles || !this.field.styles.button_type) {
      this.$set(this.field, "styles", {
        button_type: "Rectangular",
        font: {
          name: "Helvetica",
          size: 16,
          style: 0,
          color: "#409eff",
        },
        label_color: "#ffffff",
        input_background: "#f754a2",
      });
    }
    let relationalEntity;
    if (!this.field?.actions) {
      this.$set(this.field, "actions", []);
    } else {
      relationalEntity = this.field.actions.find(
        (e) =>
          e.action_button_action_type == "ESTABLISH_RELATIONSHIP" &&
          e.relational_entity_one
      );
    }
    if (this.field?.new_entity_data_mapping) {
      Object.keys(this.field.new_entity_data_mapping || {}).forEach((key) => {
        if (this.field.new_entity_data_mapping[key].includes("#")) {
          this.$set(
            this.selectedMappedTemplate,
            key,
            this.field.new_entity_data_mapping[key].split("#")[0]
          );
        }
      });
    }
    setTimeout(() => {
      this.refresh = false;
    }, 100);
    let formBuilders = [], docTemplates = [];
    [this.entities, this.entityViews, formBuilders, docTemplates] =
      await Promise.all([
        this.fetchAllEntities(),
        this.fetchAllEntityViews(),
        postAPICall("GET", "/formBuilders", { get_all: true }),
        postAPICall("GET", "/document-templates", { get_all: true }),
      ]);
    if (this.field?.actions?.length) {
      await Promise.all(
        this.field.actions.map(async (action) => {
          await this.getFilters(action);
        })
      );
    }
    if (relationalEntity) {
      await this.getRelatedFieldsForSelectedEntity(
        relationalEntity.relationship_other_entity
      );
    }
    if (docTemplates?.data) {
      this.documentTemplatesList = (docTemplates.data || []).map((e) => {
        return {
          value: e._id + "#" + e.configurable_document_id,
          title: e.title,
        };
      });
    }
    if (formBuilders?.data) {
      this.formbuilderList = formBuilders.data;
    }
    this.getCompanyInfo();
    this.loading = false;
  },
  methods: {
    async getFilters(action) {
      if (
        action.action_button_entity_id &&
        !this.entityFilters?.[action.action_button_entity_id]?.length
      ) {
        let filters = await fetchAllEntityFiltersByEntity({
          entityId: action.action_button_entity_id,
        });
        this.entityFilters[action.action_button_entity_id] = filters;
      }
    },
    async getCompanyInfo() {
      // this.loading = true;
      try {
        if (this.getCompanyDetails) {
          this.companyInfo = this.getCompanyDetails;
          this.field.styles.input_background =
            this.companyInfo.dialog_header_color;
        } else {
          this.companyInfo = { ...this.getContactLoginSlug };
        }
      } catch (e) {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message: e,
        });
      }
    },
    async getRelatedFieldsForSelectedEntity(entity) {
      if (entity == "self_entity") {
        this.matchingRelationEntities = [...this.entities];
      } else if (entity && entity.includes("#")) {
        let [entityId] = entity.split("#");
        let relationships = [];
        if (this.relationshipMappingObj[entityId]) {
          relationships = this.relationshipMappingObj[entityId];
        } else {
          await this.$store.dispatch(
            "entityRelationships/fetchEntityRelationships",
            { entity_id: entityId }
          );
          if (
            this.getEntityRelationships &&
            this.getEntityRelationships.length
          ) {
            relationships = this.getEntityRelationships;
          }
        }
        this.matchingRelationEntities = [
          ...this.entities.filter((e) => {
            let hasRelated = relationships.find(
              (r) =>
                (r.parent_entity_id == entityId &&
                  e._id == r.child_entity_id &&
                  r.child_relation_type == "MANY") ||
                (r.child_entity_id == entityId &&
                  e._id == r.parent_entity_id &&
                  r.parent_relation_type == "MANY")
              // (r.parent_entity_id == e._id &&
              //   r.child_entity_id == entityId) ||
              // r.child_entity_id == e._id && r.parent_entity_id == entityId
            );
            return hasRelated ? true : false;
          }),
        ];
      } else {
        this.matchingRelationEntities = [];
      }
    },
    saveMapping() {
      this.$set(
        this.field,
        "new_entity_data_mapping",
        this.selectedMappedField
      );
      this.resetFieldMappingModal();
    },
    openMapFieldsParentToChild(action) {
      this.fieldsMappingModal = true;
      this.selectedMappedField = JSON.parse(
        JSON.stringify(this.field.new_entity_data_mapping || {})
      );
      this.getSelectedEntityFields(action);
    },
    resetFieldMappingModal() {
      this.selectedMappedField = {};
      this.fieldsMappingModal = false;
    },
    async getSelectedEntityFields(action) {
      this.loading = true;
      this.relatedEntityFields = [];
      let id = action.relational_entity_one || action.selected_other_entity;
      const entity = await postAPICall("GET", `/entities/${id}`);
      this.relatedEntityName = entity?.data?.name ? entity.data.name : "Entity";
      if (entity?.data?.templates) {
        entity.data.templates.forEach((template) => {
          if (template?.templateInfo?.sections[0]?.fields) {
            this.relatedEntityFields = [
              ...this.relatedEntityFields,
              ...template.templateInfo.sections[0].fields.map((f) => {
                f.template_id = template.template_id;
                f.template_name = template.templateInfo.name;
                if (f.key && !f.key.includes("#")) {
                  f.key = template.template_id + "#" + f.key;
                }
                return f;
              }),
            ];
          }
        });
      }
      // let entity = this.entities.find((e) => e._id == id);
      // this.relatedEntityName = entity?.name ? entity.name : "Entity";
      // let templateId = entity.templates[0].template_id;
      // await this.$store.dispatch(
      //   "companyTemplates/fetchSingleCompanyTemplate",
      //   templateId
      // );
      // if (this.getSingleCompanyTemplate) {
      //   let template = this.getSingleCompanyTemplate;
      //   this.relatedEntityFields = template?.sections[0]?.fields
      //     ? template.sections[0].fields
      //     : [];
      // }
      this.loading = false;
    },

    getAnyTemplateFields(rowField, selectedTemplate) {
      let numberFields = [
        "NUMBER",
        "CURRENCY",
        "AGGREGATE_FUNCTION",
        "FORMULA",
      ];
      let selectedTemplateData = this.repeatingTemplates.find(
        (e) => e._id == selectedTemplate
      );
      let fields = [];
      if (selectedTemplateData?.sections?.[0]?.fields) {
        fields = selectedTemplateData.sections[0].fields;
      } else if (selectedTemplate == "current_template") {
        fields = this.fieldsData;
      }
      return fields.filter((field) => {
        if (
          field.inputType == rowField.inputType ||
          (numberFields.includes(rowField.inputType) &&
            numberFields.includes(field.inputType)) ||
          (rowField.inputType == "FORMULA" &&
            field.inputType == rowField.result_type) ||
          (rowField.inputType == "SINGLE_LINE_TEXT" &&
            field.inputType == "AUTO_INCREMENT_NUMBER")
        ) {
          field.template_id = selectedTemplate;
          return true;
        }
      });
    },
    getTooltipContent(type, entityId) {
      let label = "";
      let entity = this.entities.find((e) => e._id == entityId);
      let entityName = entity?.name || "Selected entity";
      switch (type) {
        case "assign_only":
          label = "Can only assign existed " + entityName + " to self entity";
          break;
        case "add_only":
          label =
            "Can only add new " +
            entityName +
            " and that " +
            entityName +
            " will be assign to self entity";
          break;
        case "assign_add":
          label =
            "Can add new " +
            entityName +
            " or assign existed " +
            entityName +
            " to self entity";
          break;
        case "view_data":
          label = "Can view " + entityName + " related to self entity";
          break;
        case "view_add":
          label =
            "Can view existed " +
            entityName +
            " or add new " +
            entityName +
            " related to self entity";
          break;
      }
      return label;
    },
    checkDisabled(type, i) {
      if (
        ["NAVIGATION", "TEMPLATE_ACTION", "ESTABLISH_RELATIONSHIP"].indexOf(
          type
        ) != -1
      ) {
        let existed = (this.field.actions || []).findIndex(
          (e) => e.action_button_action_type == type
        );
        if (existed > -1 && existed != i) {
          return true;
        }
        return false;
      }
      return false;
    },
    deleteAction(i) {
      this.field.actions.splice(i, 1);
    },
    addNewAction() {
      this.field.actions.push({
        ...JSON.parse(
          JSON.stringify({
            action_button_action_type: "",
            action_button_action_navigation_type: "",
            action_button_target_location: "",
            selected_fields: [],
          })
        ),
      });
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    async fetchRepeatableTemplates() {
      await this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
        get_all: true,
        is_repeatable: true,
      });
    },
    async addSelfTemplateFields() {
      this.entities = [];
      if (this.fieldsData && this.fieldsData.length) {
        this.fieldsData.forEach((element) => {
          if (element.input_type == "GLOBAL_VARIABLE") {
            element["is_template_variable"] = true;
            this.entities.push(element);
          }
        });
      }
    },

    checkAlreadyExistedInOthers(entity) {
      let relationships =
        this.field.action_button_repeating_template_relationships;
      let existedRelationship = relationships.find((x) => {
        return x.relationships && x.relationships.length
          ? x.relationships.find((y) => y.key == entity.key)
          : null;
      });

      return existedRelationship ? true : false;
    },
    async setRelationships(reset) {
      if (!reset) {
        return;
      }
      this.field.action_button_repeating_template_relationships =
        this.entities.map((entity) => {
          return {
            entity,
            relationships: [],
          };
        });
      let data = this.fieldsData.filter((field) => {
        if (
          field.input_type == "ENTITY_VARIABLE" &&
          field.relationship_entity_id
        ) {
          return {
            entity: field,
            relationships: [],
          };
        }
      });
      data = data.map(function (element) {
        return {
          entity: element,
          relationships: [],
        };
      });
      this.field.action_button_repeating_template_relationships = [
        ...this.field.action_button_repeating_template_relationships,
        ...data,
      ];
    },
    async addChildemplateFields() {
      if (this.selectedTemplateFields && this.selectedTemplateFields.length) {
        this.childEntities = [];
        this.selectedTemplateFields.forEach((element) => {
          if (element.input_type == "GLOBAL_VARIABLE") {
            element["is_template_variable"] = true;
            this.childEntities.push(element);
          }
        });
      }
    },

    async getRepeatableTemplates() {
      if (this.field.action_button_type == "TEMPLATE")
        await this.fetchRepeatableTemplates();
    },
    onSelectRepeatableTemplate(id, reset = true) {
      this.reloadRelationship = true;
      let template = this.repeatingTemplates.find((x) => {
        return x._id == id;
      });
      if (template && template.sections && template.sections.length) {
        this.selectedTemplateFields = template.sections[0].fields;
        this.addChildemplateFields();
      }

      this.setRelationships(reset);

      this.reloadRelationship = false;
    },
    handleActionTypeChange(action) {
      if (
        action.action_button_action_type == "CREATE_DUPLICATE" &&
        !action.selected_fields?.length
      ) {
        this.fieldsData.map((e) => {
          action.selected_fields.push(`${e.template_id}#${e.key}`);
        });
      }
    },
    checkIsFieldRequired(field) {
      if (
        field.validations.required ||
        field.input_type == "AUTO_INCREMENT_NUMBER" ||
        field.inputType == "AUTO_INCREMENT_NUMBER"
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.slect-style {
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.el-radio-group {
  display: flex;
  line-height: 1;
  vertical-align: middle;
}

.after-action {
  color: #3366cc;
}

.info-color {
  color: #2196f3;
}
.map-table {
  padding: 20px;
}
</style>
